.section-intro {
	background-position: center;
	background-size: cover;

	&:before {
	    content: "";
	    padding-top: 50%;
	    display: block;	
	}
}

.section-about {

	&__inner {
		.cell {		
			@include breakpoint(small down) {
				padding-left: 2rem;
				padding-right: 2rem;				
			}
		}
	}

	&__video-wrapper {	
		position: relative;
		overflow: hidden;
		border-radius: 20px;
		height: 350px;
		transform: translate3d(0, 0, 0);

		@include breakpoint(small down) {
			height: auto;
		}

		&::before {
			@include breakpoint(small down) {
			    content: "";
			    padding-top: 50%;
			    display: block;	
			}
		}	
		
		video {
			position: absolute;
			width: 100%;
			height: auto;
			top: 50%;
			left: 0;
			transform: translateY(-50%);

			@include breakpoint(small down) {
				width: 200%;
			}
		}
	}

	&__content {
		position: relative;
		margin: 70px auto;

		@include breakpoint(small down) {
			margin: 30px auto 20px;
		}
	}

	&__ens {
		position: absolute;
		right: 0;
		top: 0;
		width: 140px;
		z-index: 10;
		background-size: contain;
		margin: 25px 20px;
		background-repeat: no-repeat;
		background-position: center;
		transition: all 0.3s ease-out;
		transform: scale3d(1.0, 1.0, 1.0);

		@include breakpoint(small down) {
			width: 90px;
			margin: 15px 10px;
		}

		&:hover {
			transform: scale3d(1.1, 1.1, 1.0);
		}

		&:before {
			content: "";
			display: block;
		    padding-top: 25%;
		}
	}

	&__text {
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 30px;
		z-index: 10;
		color: #fff;
		font-size: 1.6rem;
		width: 68%;
		line-height: 1.1;
		margin: 0;
		font-family: serif;
	    font-style: italic;

		@include breakpoint(small down) {
			padding: 10px 15px;
			font-size: 1.1rem;
			width: 100%;	
		}

		span {
			font-weight: bold;
			font-family: sans-serif;
		}
	}

	.grid-container {
		@include breakpoint(medium down) {
			padding-left: 1.875rem;
			padding-right: 1.875rem;
		}

		@include breakpoint(small down) {
			padding-right: 0.625rem;
			padding-left: 0.625rem;
		}
	}
}

.section-list {
	position: relative;
	z-index: 10;

	&__heading {
		color: #fff;
		font-size: 7rem;
		font-family: serif;
		font-weight: normal;
		text-align: left;
		padding-left: 5rem;

		@media screen and (min-width: 53em) {
			padding-left: 25vw;
		}

		@include breakpoint(large down) {
		padding-left: 15vw !important;
		}		

		@include breakpoint(small down) {
			font-size: 3.2rem;
			padding-left: 2rem !important;
		}
	}
}


.section-contact {
	position: relative;
	text-align: center;
	height: 0;
	margin-top: 100px;
	z-index: 5;
	// padding: 100px 0;
	@include breakpoint(small down) {
		// margin-top: -10vh;
	}

	&::before {
		content: "";
		display: block;
		padding-top: 20%;

		@include breakpoint(large down) {
			padding-top: 30%;
		}

		@include breakpoint(medium down) {
			padding-top: 50%;
		}

		@include breakpoint(small down) {
			padding-top: 50%;
		}
	}

	&__heading {
		color: #fff;
		font-size: 7rem;
		font-family: serif;
		font-weight: normal;

		@include breakpoint(medium down) {
			font-size: 4.6rem;
		}

		@include breakpoint(small down) {
			font-size: 3.2rem;
		}
	}

	&__info {
		position: absolute;
		z-index: 10;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 10;
		width: 100%;
	}

	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	    width: 50%;
	    // margin: -30vh auto 0;
	    margin: 0 auto;
	    display: block;

		@include breakpoint(medium down) {
			margin: 0 auto;
			width: 100%;
		}	    

		.lines {
		    stroke: #fff;
		    stroke-width: 2px;
		}

		.circles {
			stroke: #328492;
			opacity: 0.5;

			use {
				opacity: 0;
			}
		}
	} 

	.button {
		background: #fff;
		color: #000;
		border-radius: 50px;
		padding: 10px 20px;
		font-size: 1.6rem;
		border: none;
		box-shadow: none;
		outline: none;	
		transition: all 0.3s ease-out;
		display: inline-block;

		@include breakpoint(small down) {
			font-size: 1.2rem;
		}

		&:hover {
			background: #78d0b5;
		}	
	}
}

.section-contact svg .circles use:nth-of-type(1) {
    fill: red;
    animation: hueShift 5s linear infinite forwards;
    // animation-delay: 0s;
}
.section-contact svg .circles use:nth-of-type(2) {
    fill: #4700ff;
    animation: hueShift 5s linear infinite forwards;
    animation-delay: 1s;
}
.section-contact svg .circles use:nth-of-type(3) {
    fill: #00f7ff;
    animation: hueShift 5s linear infinite forwards;
    animation-delay: 2s;
}
.section-contact svg .circles use:nth-of-type(4) {
    fill: #4dff00;
    animation: hueShift 5s linear infinite forwards;
    animation-delay: 3s;
}
.section-contact svg .circles use:nth-of-type(5) {
    fill: #e6ff00;
    animation: hueShift 5s linear infinite forwards;
    animation-delay: 4s;
}

@keyframes hueShift {
	0% {
		opacity: 0;
	}	
	10% {
		opacity: 0;
	}		
	50% {
		opacity: 1;
	}
	90% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	    filter: hue-rotate(360deg);
	}
}