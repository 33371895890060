/*

 █████  ██████  ██████  ██      ██  ██████  █████  ████████ ██  ██████  ███    ██
██   ██ ██   ██ ██   ██ ██      ██ ██      ██   ██    ██    ██ ██    ██ ████   ██
███████ ██████  ██████  ██      ██ ██      ███████    ██    ██ ██    ██ ██ ██  ██
██   ██ ██      ██      ██      ██ ██      ██   ██    ██    ██ ██    ██ ██  ██ ██
██   ██ ██      ██      ███████ ██  ██████ ██   ██    ██    ██  ██████  ██   ████

*/

.App {
  // background: blue;
}

.App-header {
  position: absolute;
  z-index: 10;
  width: 100%;
  mix-blend-mode: exclusion;
  overflow: hidden;
  background: #0b312b;

  img {
    max-width: 83.3%;
    margin: 0 auto;
    display: block; 
  }

  &__video {
    position: absolute;
    width: 100%;
    mix-blend-mode: exclusion;
    object-fit: cover;    
  }
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  font-size: 13px;
}

body {
  margin: 0;
  --color-text: #111;
  --color-bg: #000;
  --color-link: #fff;
  --color-link-hover: #eee;
  --color-menu: #eee;
  --color-menu-hover: #f32015;
  color: var(--color-text);
  background-color: var(--color-bg);
  --cursor-stroke: #000;
    --cursor-fill: #000;
    --cursor-stroke-width: 1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: degular, sans-serif;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
  content: '';
  position: fixed;
  z-index: 1000;
}

.js .loading::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.js .loading::after {
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  opacity: 0.4;
  background: var(--color-link);
  animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5,0.5,1);
  }
}

a {
  text-decoration: none;
  color: var(--color-link);
  outline: none;
  cursor: pointer;
}

a:hover,
a:focus {
  color: var(--color-link-hover);
  outline: none;
}

.frame {
  top: 0;
  padding: 1rem 1.5rem;
  position: fixed;
  z-index: 1000;
  background: #f0f0f0;
  width: 100%;
  display: flex;
  text-transform: uppercase;
}

.frame__title {
  font-size: 1rem;
  margin: 0;
  font-weight: 400;
}

.frame__links {
  margin: 0.5rem 0;
}

.frame__links a {
  border-bottom: 1px solid currentColor;
  transition: 0.2s border-color;
}

.frame__links a:hover,
.frame__links a:focus {
  border-color: transparent;
}

.frame__links a:not(:last-child) {
  margin-right: 1rem;
}

.frame__button {
  word-spacing: 0.75rem;
  color: var(--color-link);
  margin-left: auto;
}

.menu {
  // padding: 10rem 0 20vh 0;
  // width: 90vw;
  overflow: hidden;
  padding: 0 0 20vh 0;
  margin: 0;
  width: 100%;  
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: right;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  counter-reset: menucounter;

  @include breakpoint(large down) {
    padding-bottom: 15vh;
  }

  @include breakpoint(medium down) {
    padding-bottom: 10vh;
  }
}

.menu__item {
  flex: none;
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-left: 5rem;
  transition: all 0.3s ease-out;
  background: transparent;

  @include breakpoint(large down) {
    padding-left: 15vw !important;
    justify-content: flex-start;
  }

  @include breakpoint(small down) {
    justify-content: flex-start;
    padding-left: 2rem !important;
  }

  &:hover {
    background: #eee;
  }

  &--nft100 {
    &:hover {
      background: #E8A03A;
      color: #fff;

      .menu__item-textinner {
        color: #fff !important;
      }
    }
  }

  &--fewoworld {
    &:hover {
      background: #12BDED;
      color: #fff;

      .menu__item-textinner {
        color: #fff !important;
      }
    }
  }

  &--unwod {
    &:hover {
      background: #F48D27;
      color: #fff;

      .menu__item-textinner {
        color: #fff !important;
      }
    }
  } 
  
  &--seedclub {
    &:hover {
      background: #364B40;
      color: #fff;

      .menu__item-textinner {
        color: #fff !important;
      }
    }
  } 

  &--oceanic {
    &:hover {
      background: #084772;
      color: #fff;

      .menu__item-textinner {
        color: #fff !important;
      }
    }
  } 

  &--findings {
    &:hover {
      background: #8E9CB2;
      color: #fff;

      .menu__item-textinner {
        color: #fff !important;
      }
    }
  }  

  &--overview {
    &:hover {
      background: #A4A4A4;
      color: #fff;

      .menu__item-textinner {
        color: #fff !important;
      }
    }
  } 

  &--fewocious {
    &:hover {
      background: #F4BD52;
      color: #fff;

      .menu__item-textinner {
        color: #fff !important;
      }
    }
  } 

  &--nftnow {
    &:hover {
      background: #E24474;
      color: #fff;

      .menu__item-textinner {
        color: #fff !important;
      }
    }
  } 

  &--rooms {
    &:hover {
      background: #826E86;
      color: #fff;

      .menu__item-textinner {
        color: #fff !important;
      }
    }
  }       
}

.menu__item::before {
  counter-increment: menucounter;
  content: counters(menucounter, ".", decimal-leading-zero);
  position: absolute;
  // left: 0;
  // border-left: 3px solid currentColor;
  // top: 20%;
  left: 22vw;
  border-left: 0;
  // top: 11%;
  top: 2%;
  height: 60%;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  line-height: 1;
  font-weight: bold;
  opacity: 0;
  transform: translateY(1rem);
  transition: transform 0.3s, opacity 0.3s;
  color: #fff;

  @include breakpoint(xlarge down) {
    left: 20vw;
    top: 4%;
  }
  @include breakpoint(large down) {
    left: 10vw;
    top: 6%;
  }

  @include breakpoint(medium down) {
    left: 9vw;
    top: 10%;
  }  

  @include breakpoint(small down) {
    left: 0;
    font-size: 0.9rem;
    top: 1%;    
  }
}

.menu__item:hover::before {
  opacity: .6;
  transform: translateY(0);
}

.menu__item-text {
  position: relative;
  cursor: pointer;
  padding: 0.5rem;
  display: block;
  overflow: hidden;
  font-size: 6vw;
  z-index: 5;
}

.menu__item-textinner {
  display: block;
  font-family: goldenbook, serif;
  font-weight: 300;
  text-transform: uppercase;
  white-space: nowrap;
  color: var(--color-menu);
  font-family: sans-serif;
  letter-spacing: 3px; 

  @include breakpoint(small down) {
    line-height: 1;
    padding-bottom: 10px;    
  } 
}

.js .menu__item-textinner {
  transform: translateY(100%);
}

.menu__item:hover .menu__item-textinner {
  color: var(--color-menu-hover);
}

.menu__item-sub {
  display: none;
  text-transform: uppercase;
  font-weight: bold;
  white-space: nowrap;
  align-items: center;
  position: relative;
  margin-left: 2rem;
  padding-left: 3rem;
  opacity: 0;
  transform: translateX(2rem);
  transition: transform 0.6s, opacity 0.3s;
  color: #fff;
  font-size: 1rem;
  position: absolute;
  bottom: 17px;
  left: 25vw;
  margin: 0;
  display: inline-block !important;
  padding: 0;
  font-family: serif;
  text-transform: capitalize;
  font-weight: normal;
  font-style: italic;
  font-size: 1.4rem;
  letter-spacing: 0px;
  z-index: 5;

  @include breakpoint(large down) {
    left: 15vw !important;
    bottom: 12px;
  }

  @include breakpoint(medium down) {
    left: 16vw !important;
    bottom: 3px;
  }  

  @include breakpoint(small down) {
    left: 2.5rem !important;
    bottom: 4px;
    font-size: 1rem;
    line-height: 1;    
  }
}

.menu__item:hover .menu__item-sub {
  opacity: .6;
  transform: translateX(0);

  @include breakpoint(small down) {
    opacity: 0.8;
  }
}

.menu__item-sub::before {
  content: '';
  position: absolute;
  left: 0;
  top: 15%;
  width: 1px;
  height: 70%;
  background: currentColor;
  transform-origin: 0 100%;
  transform: rotate(22.5deg) scale3d(1,0,1);
  transition: transform 0.3s;
  opacity: 0;
}

.menu__item:hover .menu__item-sub::before {
  transform: rotate(22.5deg) scale3d(1,1,1);
}

.hover-reveal {
  position: absolute;
  // z-index: -1;
  // width: 220px;
  // height: 320px;
  width: 600px;
  height: 400px;
  z-index: 0;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;

  @include breakpoint(small down) {
    width: 150px;
    height: 100px;
    transform: none !important;
    left: auto;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%) !important;
  }

  @media screen and (max-height: 400px) {
    width: 150px;
    height: 100px;
    transform: none !important;
    left: auto;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%) !important;    
  }
}
 
.hover-reveal__inner {
  overflow: hidden;
}

.hover-reveal__inner,
.hover-reveal__img {
  width: 100%;
  height: 100%;
  position: relative;
}

.hover-reveal__img {
  background-size: cover;
  background-position: 50% 50%;
}

.cursor {
  display: none;
}

.credits {
  align-self: flex-start;
  padding: 5rem 0 0 0;
}

.credits a {
  text-decoration: underline;
}

@media screen and (min-width: 53em) {
  .frame {
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    background: none;
    height: 50vh;
    pointer-events: none;
    padding-bottom: 0;
  }
  .frame__links a:not(:last-child) {
    margin-right: 0;
  }
  .frame__links {
    margin: 0.5rem 0 2rem;
    justify-self: start;
  }
  .frame__links a {
    margin-left: 0.5rem;
    pointer-events: auto;
  }
  .frame__button {
    cursor: not-allowed;
    pointer-events: auto;
    margin-top: auto;
    transform: translateY(50%);
  }
  .menu {
    // padding-top: 20vh;
  }
  .menu__item {
      padding-left: 25vw;
      justify-content: initial;
    }
  .menu__item-text {
    // padding: 1vh 0;
    // font-size: 4vw;
    // padding: 2vh 0;
    padding: 1vh 0 3vh;
    font-size: 2.8vw;    
  }
  .menu__item-sub {
    display: flex;
  }
}

@media (any-pointer: fine) {
  .cursor {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    pointer-events: none;
  }
  .cursor__inner {
    fill: var(--cursor-fill);
    stroke: var(--cursor-stroke);
    stroke-width: var(--cursor-stroke-width);
    opacity: 0.3;
  }
  .credits {
    padding-left: 25vw;
  }
}