/*

 █████  ██████  ██████
██   ██ ██   ██ ██   ██
███████ ██████  ██████
██   ██ ██      ██
██   ██ ██      ██

*/

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@400;700&display=swap');

// Imports
@import 'essentials'; // foundation loaded here
@import 'variables';
@import 'functions';
@import 'mixins';
@import 'utils';
@import 'placeholders';

// Application Styling
@import 'application';
@import 'home';
