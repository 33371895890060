/*

██████  ██       █████   ██████ ███████ ██   ██  ██████  ██      ██████  ███████ ██████  ███████
██   ██ ██      ██   ██ ██      ██      ██   ██ ██    ██ ██      ██   ██ ██      ██   ██ ██
██████  ██      ███████ ██      █████   ███████ ██    ██ ██      ██   ██ █████   ██████  ███████
██      ██      ██   ██ ██      ██      ██   ██ ██    ██ ██      ██   ██ ██      ██   ██      ██
██      ███████ ██   ██  ██████ ███████ ██   ██  ██████  ███████ ██████  ███████ ██   ██ ███████

*/

// Clearfix
%clearfix {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

// Center block
%center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// Aspect Ratio Classes
.onebyone {
	@include aspect-ratio(1, 1);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.twobyone {
	@include aspect-ratio(2, 1);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.onebytwo {
	@include aspect-ratio(1, 2);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.onebyonehalf {
	@include aspect-ratio(1, 1.5);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.fourbythree {
	@include aspect-ratio(4, 3);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.sixteenbynine {
	@include aspect-ratio(16, 9);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
