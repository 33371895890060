/*

███    ███ ██ ██   ██ ██ ███    ██ ███████
████  ████ ██  ██ ██  ██ ████   ██ ██
██ ████ ██ ██   ███   ██ ██ ██  ██ ███████
██  ██  ██ ██  ██ ██  ██ ██  ██ ██      ██
██      ██ ██ ██   ██ ██ ██   ████ ███████

*/

// Anti-Alias
@mixin antialias($value: null) {
    @if not & and $value==null {
        *,
        *::before,
        *::after {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    } @else if & and $value=="only" {
        &,
        &::before,
        &::after {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    } @else if & and $value==null {
        &,
        &::before,
        &::after,
        *,
        *::before,
        *::after {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
}

// Break Out of a Grid
@mixin break-row {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

// Highlights
@mixin selection {
    ::-moz-selection {
        @content;
    }

    ::selection {
        @content;
    }
}

// Unselectable
@mixin unselectable {
    -webkit-touch-callout: none;
    user-select: none;
}

// Reset Box
@mixin reset-box {
    padding: 0;
    margin: 0;
}

// Center Block
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Box Shadow
@mixin box-shadow($params) {
    -webkit-box-shadow: $params;
    -moz-box-shadow: $params;
    box-shadow: $params;
}

// Center
@mixin center($horizontal: true, $vertical: true) {
    position: absolute;

    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    } @else if ($horizontal) {
        left: 50%;
        transform: translate(-50%, 0);
    } @else if ($vertical) {
        top: 50%;
        transform: translate(0, -50%);
    }
}

// Aspect Ratio
@mixin aspect-ratio($width, $height) {
    position: relative;

    &::before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    >.content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

// Border Radius
@mixin border-radius($radius: 0.5em) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

// Hide from Screen-readers
@mixin hidden {
    display: none;
    visibility: hidden;
}

// Generic Transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

// Transition
@mixin transition($args) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

// Filters
@mixin blur($amount) {
    filter: blur($amount);
    -webkit-filter: blur($amount);
}

// Black & White
@mixin greyscale($amount) {
    filter: grayscale($amount);
    -webkit-filter: grayscale($amount);
}

// Rotate
@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}

// Opacity
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

// Truncate
@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Truncate (Line Clamp)
@mixin line-clamp($lines: 3) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

// Pseudo Content
@mixin pseudo($display: block, $pos: absolute, $content: "") {
    content: $content;
    display: $display;
    position: $pos;
}

// Retina Images
// @mixin retina {
//  @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
//      only screen and (-moz-min-device-pixel-ratio: 1.5),
//      only screen and (-o-min-device-pixel-ratio: 3 / 2),
//      only screen and (min-device-pixel-ratio: 1.5),
//      only screen and (min-resolution: 1.5dppx) {
//      @content;
//  }
// }

// Fade Animation
@keyframes fade_up {
    0% {
        @include opacity(0);
        @include transform(translate(0, 70px));
    }

    100% {
        @include transition(transform 1.1s ease-out);
        @include transform(translate(0, 0));
        @include opacity(1);
    }
}

@keyframes small_big {
    0%,
    100% {
        @include transform(scale(1));
    }

    50% {
        @include transform(scale(0));
    }
}
