/*

██    ██ ████████ ██ ██      ███████
██    ██    ██    ██ ██      ██
██    ██    ██    ██ ██      ███████
██    ██    ██    ██ ██           ██
 ██████     ██    ██ ███████ ███████

*/

// Font Size Override
body {
	font-family: var(--font-family);
	font-weight: var(--font-weight--normal);
	font-size: 24px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: var(--font-weight--bold);
	font-family: var(--font-family);
}

hr {
	border-color: var(--color-grey);
}

// Link Color Override
a {
	color: var(--color-white);
	font-family: var(--font-family);
	font-weight: var(--font-weight--normal);
}

// clearfix: http://nicolasgallagher.com/micro-clearfix-hack/
.clear {

	&::before,
	&::after {
		content: " ";
		display: table;
	}

	&::after {
		clear: both;
	}
}

// Flip Columns on Mobile
.mobile-column-reverse {
	@include breakpoint(medium down) {
		display: flex;
		flex-direction: column-reverse;
	}
}

// Buttons
.button {
  // background-color: var(--color-white);
  // color: var(--color-black);
  font-weight: var(--font-weight--bold);
  line-height: 1.5;
  margin: 0;
  padding: 0 var(--grid-margin);
  font-size: 1rem;
  height: 53px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #fff;
	background: transparent;
	border: 1px solid;		

	@include breakpoint(small down) {
		font-size: 0.7rem;
		padding: 10px 10px;
		height: auto;
	}

	&:hover,
	&:focus {
		background-color: var(--color-grey);
		color: var(--color-white);
	}
}

// Animations
.animate {
	// @include opacity(0);
	// @include transform(translate(0, 100px));
	-webkit-animation-duration: 1.1s;
	animation-duration: 1.1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;

	&.fade-up {
		-webkit-animation-name: fade_up;
		animation-name: fade_up;
	}

	&.small-big {
		-webkit-animation-name: small_big;
		animation-name: small_big;
	}
}

// Text Align
.text-align {
	&__right {
		text-align: right;
	}

	&__center {
		text-align: center;
	}
}
