/*

██    ██  █████  ██████  ██  █████  ██████  ██      ███████ ███████
██    ██ ██   ██ ██   ██ ██ ██   ██ ██   ██ ██      ██      ██
██    ██ ███████ ██████  ██ ███████ ██████  ██      █████   ███████
 ██  ██  ██   ██ ██   ██ ██ ██   ██ ██   ██ ██      ██           ██
  ████   ██   ██ ██   ██ ██ ██   ██ ██████  ███████ ███████ ███████

*/


:root {
  // Colors
  --color-yellow: #fccd35;
  --color-green: #00cc00;
  --color-white: #fff;
  --color-grey: #333;
  // --color-black: #0a0a0a;
  --color-black: #000;
  --color-darker-black: #0e0e0e;

  // Fonts
  --font-family: 'Be Vietnam Pro', sans-serif;
  --font-family--body: 'Be Vietnam Pro', sans-serif;
  --font-family--titles: 'Be Vietnam Pro', sans-serif;
  --font-weight--normal: 400;
  --font-weight--bold: 700;

  // Grid
  --grid-margin: 1rem;

  // Z-Index
  --z-index--under: -1;
  --z-index--standard: 1;
  --z-index--over: 2;
  --z-index--nav: 9;
  --z-index--modal: 10;

  // Fixed Sizes
  --height__nav: 75px;
  --height__nav--offset: calc(100ch - 75px);

  // Border Radius
  --border-radius__small: var(--grid-margin);
  --border-radius__large: var(--grid-margin) * 2;
}

// Social Colors
$color__facebook: #3b5998;
$color__facebook-messenger: #0084ff;
$color__twitter: #00aced;
$color__foursquare: #f94877;
$color__tumblr: #35465d;
$color__medium: #02b875;
$color__vimeo: #1ab7ea;
$color__android: #a4c639;
$color__dribbble: #ea4c89;
$color__slack: #4a154b;
$color__reddit: #ff4500;
$color__spotify: #1ed760;
$color__amazon: #f90;
$color__yahoo: #430297;
$color__twitch: #6441a4;
$color__pocket: #ee4056;
$color__periscope: #40a4c4;
$color__discord: #7289da;
$color__telegram: #08c;
$color__apple: #a6b1b7;
$color__pinterest: #bd081c;
$color__wordpress: #21759b;
$color__quora: #b92b27;
$color__snapchat: #fffc00;
$color__linkedin: #0077b5;
$color__soundcloud: #f30;
$color__whatsapp: #25d366;
$color__flickr: #f40083;
$color__youtube: #f00;
$color__wechat: #09b83e;
$color__instagram: #c32aa3;
